<template>
  <Main
    v-if="loading"
    :service-status-list="serviceStatusList"
    type="firstRepair"
    name="初修照片"
    :tab-list="tabList"
    base-service-type="5"
  />
</template>

<script>
import Main from '../main.vue'

export default {
  components: {
    Main,
  },
  data() {
    return {
      tabList: [
        { name: '全部初修服务列表', status: 1, show: false },
        { name: '超时未接单', status: 2, statusCount: 0, show: false },
        { name: '我的初修服务列表', status: 3, statusCount: 0, show: true },
      ],
      serviceStatusList: [
        { name: '待接单', val: 1 },
        { name: '已拒绝', val: 7 },
        { name: '服务中', val: 3 },
        { name: '待评价', val: 4 },
        { name: '已评价', val: 5 },
      ],
      loading: false,
    }
  },
  mounted() {
    const btns = JSON.parse(window.sessionStorage.getItem('btnPermissions'))
    if (btns && btns.find((e) => e.nodeCode === 'GY-SERVICE-FIRSTREPAIR-ALL'))
      this.tabList[0].show = true
    if (btns && btns.find((e) => e.nodeCode === 'GY-SERVICE-FIRSTREPAIR-NOTORDER'))
      this.tabList[1].show = true
    this.loading = true
  },
}
</script>

<style lang="less" scoped></style>
